import React from 'react';
import { CategoriesGallery } from '@mshops-components-library/categories-gallery';
import { useRenderContext } from '../../pages/home/context';

const Wrapper = (props) => {
  const { isEshops } = useRenderContext();

  return (
    <CategoriesGallery
      {...props}
      isEshops={isEshops}
    />
  );
};

export default Wrapper;
